
import React, { useState } from 'react'
import farazKhan from '../images/farazkhan.jpg'
import SectionHeading from './SectionHeading'

const AboutSection = () => {
    const [showMore, setShowMore] = useState(false)
    return (
        <div className="px-4 mx-auto overflow-x-hidden md:px-16 sm:container">
            <div className="flex flex-col justify-center min-h-screen space-y-3">
                <SectionHeading>About me.</SectionHeading>
                <div className="flex flex-col-reverse md:grid md:grid-cols-1 md:gap-8 md:grid-cols-2 ">
                    <div className="space-y-2 transition duration-200">
                        <p>
                            I’m a full stack developer currently working at                  <a
                                className="border-b border-black"
                                href="https://citymall.live"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                CityMall
                            </a>
                            , extremely good with frontend and design, have great experience with *nix

                            systems. My passion is building simple, beautiful websites.{' '}
                        </p>{' '}
                        <p>
                            I've some Professional Experience (Mentioned Below) apart from that
                            {showMore
                                ? `
              , Most of my Projects are hobby
              projects which were done on a weekend or in a short span of time which includes web apps and scripts to
              optimize my workflow.
              `
                                : '...'}
                        </p>
                        {!showMore && (
                            <button
                                className="flex items-start text-sm border-b border-b-2 border-black md:text-base"
                                onClick={() => setShowMore(true)}
                            >
                                <span className="cursor-pointer">Read More </span>
                            </button>
                        )}
                        {showMore && [
                            <p>
                                The most challenging project I did was setting up Arch Linux with no scripts back in summer of 2018.
                            </p>,
                            <p>
                                Out of the two hackathons I've participated I've won one as a second runner up at BitCamp 2019 and came
                                5/100 at DMCE Hackathon in 2020.{' '}
                            </p>,
                            <p>
                                In my free time I like gaming (CS:GO), Watching Tech Conferences on Youtube. (Playlist here) and Ricing
                                my linux (Dotfiles here). I’d have mentioned music but my taste is totally random and incorehent (
                                <a
                                    className="border-b border-black"
                                    href="https://www.youtube.com/watch?v=jxKjOOR9sPU"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    example
                                </a>
                                )
                            </p>,

                            <button
                                className="flex items-center text-sm border-b border-b-2 border-black md:text-base"
                                onClick={() => setShowMore(false)}
                            >
                                <span className="cursor-pointer">Read Less</span>
                            </button>,
                        ]}
                    </div>
                    <img
                        src={farazKhan}
                        alt=""
                        className={` h-40 md:h-56 shadow-xl mr-auto md:mr-0 hover:shadow-2xl md:block block bg-red-500 md:ml-auto flex-shrink-0 transform  duration-150 hover:scale-105 mb-4 md:mb-0`}
                    />
                </div>
            </div>
        </div>
    )
}

export default AboutSection;