

import styled from 'styled-components'

const SlidingTitleWrapper = styled.div`
  overflow: hidden;
  transition: 200ms all 150ms;
  @media screen and (min-width: 640px) {
    font-size: 1rem;
    opacity: 1;
    width: ${(props) => (props.scrolled ? '100%' : '0%')};
  }
`
const SlidingTitle = styled.span`
  position: relative;
  font-size: 1rem;
  opacity: ${(props) => (props.scrolled ? 0 : 1)};
  transition: 200ms all 150ms;
  font-size: 0.875rem;
  z-index: 1;
  width: 1rem;
  white-space: nowrap;
  @media screen and (min-width: 640px) {
    font-size: 1rem;
    opacity: 1;
  }
`

export { SlidingTitle, SlidingTitleWrapper }