import React from 'react'
import './App.css'
import Container from './components/Container'
import DesignProjectsSection from './components/Design'
import Hero from './components/Hero'
import PersonalProjects from './components/PersonalProjects'
import ProfessionalWork from './components/ProfessionalWork'
import useInit from './hooks/useInit'
import Navbar from './components/NavBar'
import AboutSection from './components/About'
import Footer from './components/Footer'
import ContactSection from './components/Contact'

function App() {
  useInit()
  return (
    <>
      <Navbar />
      <Container>
        <Hero />
        <AboutSection />
        <ProfessionalWork />
        <PersonalProjects />
        <DesignProjectsSection />
        <ContactSection />
        <Footer />
      </Container>
    </>
  )
}





export default App
