
import React from 'react'
import { SocialIcon } from 'react-social-icons'
const socialMediaLinks = ['https://github.com/frzkn', 'https://dribbble.com/frzkn', 'https://linkedin.com/in/frzkn']

const Footer = () => {
    return (
        <footer className="flex flex-col items-center p-4 md:p-4">
            <a href="mailto:farazrk001@gmail.com" className="border-b border-black">
                farazrk001@gmail.com
            </a>
            <div className="flex flex-wrap justify-center p-8 space-x-2 text-center md:p-12">
                {socialMediaLinks.map((link) => (
                    <SocialIcon url={link} bgColor="#FFF" fgColor="#000" key={link} />
                ))}
            </div>
        </footer>
    )
}

export default Footer