import React from 'react'
import SectionHeading from './SectionHeading'
import SlidingImageWrapper from './SlidingImageWrapper'

import suryaTravels from '../images/work/suryatravels.png'
import blockchainAtIndia from '../images/work/blockchainatindia.png'

const ProfessionalWork = () => {

    return (
        <div className="px-4 mx-auto overflow-x-hidden md:px-16 sm:container">
            <div className="flex flex-col justify-center min-h-screen space-y-3" id="ProfessionalWork">
                <SectionHeading>Professional Work.</SectionHeading>

                <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                    <div className="flex flex-col space-y-3">
                        <SlidingImageWrapper>
                            <img src={suryaTravels} className="work" alt="" draggable="false" />
                        </SlidingImageWrapper>

                        <div className="flex items-center justify-between">
                            <span>Surya Travels Website</span>
                            <a href="https://www.suryatravelsmumbai.com/" target="_blank" rel="noopener noreferrer">
                                <svg
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className="flex flex-col space-y-3">
                        <SlidingImageWrapper>
                            <img src={blockchainAtIndia} className="work" alt="" draggable="false" />
                        </SlidingImageWrapper>
                        <div className="flex items-center justify-between">
                            <span>Blockchain at India</span>
                            <a href="https://blockchainatindia.com/" target="_blank" rel="noopener noreferrer">
                                <svg
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfessionalWork