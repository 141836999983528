
import React, { useCallback, useEffect, useState } from 'react'
import profilePhoto from '../images/farazkhan.jpg'
import { SlidingTitleWrapper, SlidingTitle } from './SlidingTitleWrapper'


const Navbar = () => {
    const [scrolled, setScrolled] = useState(false)

    const handleScroll = useCallback((event) => {
        let path = event.path || (event.composedPath && event.composedPath())
        if (path[1].scrollY !== 0) {
            setScrolled(true)
        } else setScrolled(false)
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [handleScroll])

    let scrolledHeaderStyles = scrolled ? 'px-4 py-2 md:px-16 md:py-4 ' : 'px-4 py-4 md:px-16 md:py-8'

    return (
        <div className={`fixed z-50 justify-center w-full bg-translucent ${scrolled && 'shadow-sm'}`}>
            <header
                className={`  flex max-w-screen-xl mx-auto items-start justify-between transition-all duration-200 z-50 w-full ${scrolledHeaderStyles}`}
            >
                <a href="#WelcomeSection" className="flex flex-shrink-0 mr-3 md:mr-8">
                    <img
                        src={profilePhoto}
                        alt=""
                        className={`block bg-red-500 w-10 h-10  transition-all duration-200  ${scrolled ? '' : 'mt-3'
                            } rounded-full`}
                    />
                </a>
                <div className={`flex items-start justify-between scrolled? transform translate-y-2 flex-grow `}>
                    <div className="flex">
                        <a href="#WelcomeSection" className="flex flex-col">
                            <span className="font-bold tracking-tight">Faraz Khan.</span>
                            <SlidingTitleWrapper scrolled={!scrolled}>
                                <SlidingTitle scrolled={scrolled}>Mobile · Fullstack · Design </SlidingTitle>
                            </SlidingTitleWrapper>
                        </a>
                    </div>

                    <a
                        href="#ContactMe"
                        className={` absolute right-0 transform md:translate-x-0 transition-opacity duration-200 delay-150 flex-shrink-0 text-sm  md:text-base border-b border-b-2 border-black  ${scrolled ? 'opacity-100' : 'opacity-0 md:opacity-100'
                            } `}
                    >
                        Get in touch
                    </a>
                </div>
            </header>
        </div>
    )
}

export default Navbar;