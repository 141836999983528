
import { useEffect } from 'react'
import ReactGA from 'react-ga'

const initGa = () => {
    ReactGA.initialize('UA-170120097-1')
    ReactGA.pageview('/')
}
const useInit = () => {
    useEffect(() => {
        initGa()
    }, [])
}

export default useInit;