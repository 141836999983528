import React from 'react'
import dchat from '../images/web/dchat-logo.png'
import feedback from '../images/web/feedback-logo.png'
import mewsick from '../images/web/mewsick-logo.png'
import smashkeys from '../images/web/smashkeys-logo.png'
import SlidingImageWrapper from './SlidingImageWrapper'
import SectionHeading from './SectionHeading'

const WebProjectCard = ({ title, source, subtitle, liveLink, sourceCode }) => {
    return (
        <div className="flex flex-col space-y-3">
            <SlidingImageWrapper>
                <div className="relative flex flex-col items-center justify-center w-full h-full space-y-4 bg-white">
                    <img src={source} className="w-32 h-32 " alt={title} draggable="false" />
                    <p className="absolute px-4 text-center transform translate-y-40 bg-gray-100">{subtitle}</p>
                </div>
            </SlidingImageWrapper>
            <div className="flex items-center justify-between">
                <span>{title}</span>
                <span className="flex items-center space-x-4 text-sm">
                    <a href={sourceCode} target="_blank" rel="noopener noreferrer">
                        <svg
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"></path>
                        </svg>
                    </a>

                    <a href={liveLink} target="_blank" rel="noopener noreferrer">
                        <svg
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path>
                        </svg>
                    </a>
                </span>
            </div>
        </div>
    )
}
const WebProjectsSection = () => {
    return (
        <div className="px-4 mx-auto overflow-x-hidden md:px-16 sm:container">
            <div className="flex flex-col justify-center min-h-screen space-y-3">
                <SectionHeading>Personal Projects.</SectionHeading>
                <div className="flex justify-end">
                    <a
                        href="https://github.com/frzkn"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="border-b border-b-2 border-black "
                    >
                        View more on GitHub
                    </a>
                </div>

                <div className="grid grid-cols-1 gap-8 md:grid-cols-4">
                    <WebProjectCard
                        title="Mewsick"
                        source={mewsick}
                        subtitle="Mewsick is an online music streaming and downloading website."
                        sourceCode="https://github.com/frzkn/mewsick"
                        liveLink="https://mewsick.herokuapp.com"
                    />
                    <WebProjectCard
                        title="Feedback Portal"
                        source={feedback}
                        subtitle="Feedback Portal featuring comments and subcomments."
                        sourceCode="https://github.com/frzkn/feedback-client"
                        liveLink="https://feedbackportal.netlify.app/"
                    />
                    <WebProjectCard
                        title="Smashkeys"
                        source={smashkeys}
                        subtitle="Smashkeys is a sixty seconds typing speed tester."
                        sourceCode="https://github.com/frzkn/smashkeys"
                        liveLink="https://smashkeys.netlify.app/"
                    />
                    <WebProjectCard
                        title="ĐChat"
                        source={dchat}
                        subtitle="ĐChat is a decentralized chat app on IPFS."
                        sourceCode="https://github.com/frzkn/dchat-ipfs"
                        liveLink="https://dchat.netlify.app/"
                    />
                </div>
            </div>
        </div>
    )
}

export default WebProjectsSection;