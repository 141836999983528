import useInView from "use-in-view"
import React from 'react';

const SectionHeading = ({ children }) => {
    const offset = 0
    const [ref, inView] = useInView(offset)
    return (
        <div className="overflow-hidden ">
            <h1
                className={`mt-32 text-3xl font-bold md:mt-0 md:text-6xl transform translate-y-20 duration-500 tracking-tight ${inView && 'translate-y-px '
                    } `}
                ref={ref}
            >
                {children}
            </h1>
        </div>
    )
}

export default SectionHeading