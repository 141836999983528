
import React, { useState } from 'react'
import SectionHeading from './SectionHeading'
import { submitContactQuery } from '../api/contact'

const SuccessModal = ({ modalState, setModalState }) => {
    return (
        <div
            className={` transform fixed  duration-200 left-0 right-0 bottom-0 top-0 h-full w-full justify-center items-center flex z-99 ${modalState ? 'scale-100' : 'scale-0'
                }`}
        >
            <div className="flex flex-col items-center justify-center w-64 h-64 p-8 space-y-2 bg-gray-400 rounded shadow-2xl bg-translucent">
                <svg fill="currentColor" viewBox="0 0 20 20" className="w-16 h-16">
                    <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                    ></path>
                </svg>
                <div className="text-center ">
                    <p className="font-bold">Message sent!</p>
                    <p className="">Thank you, I'll get in touch as soon I get to see this.</p>
                </div>
                <button className="w-full px-2 py-1 text-white bg-black rounded" onClick={() => setModalState(false)}>
                    Close
                </button>
            </div>
        </div>
    )
}

const ServiceItem = ({ children }) => {
    return (
        <li className="flex items-center p-2">
            <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6 mr-4 text-gray-800">
                <path
                    fillRule="evenodd"
                    d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                ></path>
            </svg>
            <span>{children}</span>
        </li>
    )
}

const ContactSection = () => {
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const [showSuccessModal, setShowSuccessModal] = useState(false)


    const handleSubmit = (e) => {
        submitContactQuery()
            .then(() => {
                setShowSuccessModal(true)
                setFirstname('')
                setLastname('')
                setEmail('')
                setMessage('')
            })
            .catch((error) => alert(error))
        e.preventDefault()
    }

    return <>
        <div className="px-4 mx-auto overflow-x-hidden md:px-16 sm:container">
            <div className="flex flex-col justify-center min-h-screen space-y-3" id="ContactMe">
                <SectionHeading>Contact me.</SectionHeading>
                <div className="flex flex-col-reverse md:grid md:grid-cols-1 md:grid-cols-2 md:gap-16 md:mt-8">
                    <form
                        className="flex flex-col items-stretch justify-center w-full mt-8 md:mt-0"
                        name="contact"
                        data-netlify="true"
                        netlify-honeypot="bot-field"
                        onSubmit={handleSubmit}
                    >

                        <div className="flex space-x-4">
                            <div className="flex flex-col flex-grow w-full ">
                                <label htmlFor="firstname">Firstname</label>
                                <input
                                    type="text"
                                    placeholder=""
                                    id="firstname"
                                    required
                                    className="w-full border"
                                    value={firstname}
                                    onChange={(e) => setFirstname(e.target.value)}
                                    name="firstName"
                                />
                            </div>

                            <div className="flex flex-col flex-grow w-full ">
                                <label htmlFor="lastname">Lastname</label>
                                <input
                                    type="text"
                                    placeholder=""
                                    id="lastname"
                                    className="w-full border"
                                    value={lastname}
                                    onChange={(e) => setLastname(e.target.value)}
                                    name="lastName"
                                />
                            </div>
                        </div>
                        <label htmlFor="email">Email Address</label>
                        <input
                            type="email"
                            id="email"
                            placeholder=""
                            className="w-full border"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            name="email"
                            required
                        />
                        <label htmlFor="message">Your message</label>
                        <textarea
                            id="message"
                            placeholder=""
                            required
                            className="w-full border"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            name="message"
                        />
                        <div className="flex justify-end">
                            <button type="submit" className="px-4 py-2 text-white bg-black border rounded">
                                Send Message
                            </button>
                        </div>
                    </form>
                    <div className="">
                        <p className="py-2 ">
                            I am available for remote full-time position as a Frontend/ Fullstack developer and Fullstack/ UI
                            designing freelance work. If you wanna hire me for freelance work I can offer the following services for
                            your business{' '}
                        </p>
                        <ul>
                            <ServiceItem>Building your Online Presence</ServiceItem>
                            <ServiceItem>Frontend Design</ServiceItem>
                            <ServiceItem>Frontend Development</ServiceItem>
                            <ServiceItem>Design Reviews</ServiceItem>
                            <ServiceItem>Fullstack Web Apps</ServiceItem>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <SuccessModal modalState={showSuccessModal} setModalState={setShowSuccessModal} />,
    </>
}

export default ContactSection;