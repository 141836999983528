
import React from 'react'

import WaterWave from 'react-water-wave'
import useErrorBoundary from 'use-error-boundary'
import useInView from 'use-in-view'
import rippleBg from '../images/ripple-bg-2.png'
import SectionHeading from './SectionHeading'


const SlideRightLink = ({ children }) => {
    const offset = 30
    const [ref, inView] = useInView(offset)
    return (
        <div className="mt-4 overflow-hidden">
            <button
                className={`flex items-center text-sm border-b-2 border-black md:text-base transform duration-500 -translate-x-32 ${inView && '-translate-x-px'
                    }`}
                ref={ref}
            >
                <a href="#ProfessionalWork" className="cursor-pointer pointer-events-auto">
                    {children}
                </a>
                <svg
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-4 h-4 ml-2"
                >
                    <path d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
                </svg>
            </button>
        </div>
    )
}


const SlideUpP = ({ children }) => {
    const offset = 30
    const [ref, inView] = useInView(offset)
    return (
        <div className="overflow-hidden">
            <p
                className={`max-w-2xl text-2xl md:text-4xl transform translate-y-20 duration-500  ${inView && 'translate-y-px '
                    } `}
                ref={ref}
            >
                {children}
            </p>
        </div>
    )
}


const WelcomeSection = () => {
    const { ErrorBoundary } = useErrorBoundary()

    return (
        <div className="" id="WelcomeSection">
            <div className="px-4 mx-auto overflow-visible ">
                <div className="relative z-10 flex flex-col justify-center min-h-screen transform -translate-y-32 pointer-events-none md:px-16 sm:container md:translate-y-0">
                    <SectionHeading>Greetings!</SectionHeading>
                    <SlideUpP> welcome to my fullstack & design portfolio</SlideUpP>
                    <SlideRightLink>See my work</SlideRightLink>
                </div>
            </div>
            <ErrorBoundary
                render={() => <WaterWave imageUrl={rippleBg} className="waterwave"></WaterWave>}
                renderError={() => <img src={rippleBg} className="waterwave" alt="rippleBg"></img>}
            />
        </div>
    )
}

export default WelcomeSection;