import React from 'react'
import useInView from 'use-in-view'
import styled from 'styled-components'

const SlidingImage = styled.picture`
  background-color: black;
  display: block;
  height: ${(props) => (props.noHeight ? 'auto' : '16rem')};
  width: 100%;
  position: relative;
  overflow: hidden;
  /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); */
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 1);

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    transition: 0.8s transform;
    height: 100%;
    width: 100%;
    transform: ${(props) => (props.inView ? 'translateX(100%)' : 'translateX(0%)')};
  }

  & > img.work {
    cursor: pointer;
    object-fit: cover;
    object-position: center top;
    height: 100%;
    width: 100%;
    /* transition: 0.4s transform; */
    transition: 4s object-position;
  }

  & > img.work:hover {
    object-position: center bottom;
  }
`

const SlidingImageWrapper = ({ children, noHeight = false }) => {
    const offset = 0
    const [ref, inView] = useInView(offset)

    return (
        <SlidingImage inView={inView} ref={ref} noHeight={noHeight}>
            {children}
        </SlidingImage>
    )
}

export default SlidingImageWrapper