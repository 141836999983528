import { encodeObject } from "../utils/URL"

const submitContactQuery = ({ firstname, lastname, email, message }) => fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encodeObject({
        'form-name': 'contact',
        firstname,
        lastname,
        email,
        message,
    }),
})

export {
    submitContactQuery
}