
import React from 'react'

import landingPage from '../images/design/landing-page.png'
import userProfile from '../images/design/user-profile.png'

import SlidingImageWrapper from './SlidingImageWrapper'
import SectionHeading from './SectionHeading'



const DesignProjectsSection = () => {
    return (
        <div className="px-4 mx-auto overflow-x-hidden md:px-16 sm:container">
            <div className="flex flex-col justify-center min-h-screen space-y-3">
                <SectionHeading>UI Design.</SectionHeading>
                <div className="flex justify-end">
                    <a
                        href="https://dribbble.com/frzkn"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center border-b border-b-2 border-black"
                    >
                        View more on Dribbble
                    </a>
                </div>

                <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                    <div className="flex flex-col space-y-3">
                        <SlidingImageWrapper noHeight={true}>
                            <img src={landingPage} className="object-fill object-top w-full" alt="" />
                        </SlidingImageWrapper>
                        <span>Landing Page</span>
                    </div>
                    <div className="flex flex-col space-y-3">
                        <SlidingImageWrapper noHeight={true}>
                            <img src={userProfile} className="object-cover w-full" alt="" />
                        </SlidingImageWrapper>
                        <span>User Profile Page</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DesignProjectsSection;